.requested-teacher-body-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* border: 2px solid green; */
}

.requested-teacher-content-box{
    width: 23%;
    border: 2px solid red;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 60px 0px 60px 20px;
    border-radius: 5px;
    max-width: 25%;
    margin-right: 30px;
}

.requested-teacher-content-box h4{
    font-size: 18px;
}

.requested-teacher-content-box h4 span{
    color: blueviolet;
}