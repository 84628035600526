.user-detail-view {
  padding: 30px;
  .user-image-with-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 90px;
      height: 90px;
      border-radius: 90px;
      object-fit: cover;
    }
    h4 {
      margin:  15px 0;
      color: $primary;
      font-size: 19px;
      font-family: $font-bold;
      text-transform: capitalize;
    }
  }
  ul {
    list-style: none;
    margin: 15px 0 0 0 !important;
    padding: 0;
    li {
      padding: 10px 0;
      border-bottom: 1px solid $stroke-color;
      display: flex;
      span {
        color: $text-color;
        font-size: 15px;
        font-family: $font-medium;
        flex: 0.5;
        &.text-with-image {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            margin-right: 15px;
          }
        }
        &:last-child {
          color: $primary;
        }
      }
    }
  }
  .credential{
    margin: 15px 0px 0px 0px;
    h4{
      font-size: 19px;
    }
    .first{
      margin-top: 15px;
    }
  }
}
