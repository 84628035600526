.switchWithtag {
  .ant-form-item-control-input-content {
    display: flex !important;
  }
}

.categories-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
  margin: 0 -7.5px;
  .curriculum-card {
    width: calc(50% - 15px);
    margin: 7.5px;
    display: flex;
    min-height: 286px;
    flex-direction: column;

    .curriculum-card-header {
      h1 {
        margin: 0;
        color: $primary;
        font-size: 20px;
        flex: 1 1;
        padding: 0;
        font-family: $font-bold;
      }

      .select-groups {
        display: flex;
        margin: 15px 0 20px;

        .select-group {
          width: calc(50% - 10px);
          color: $text-color;
          font-family: $font-medium;
          font-size: 15px;
          border-radius: 9px;
          border: 1px solid $stroke-color;
          padding: 10px 15px;
          box-shadow: 0 2px 9px rgba(1, 3, 17, 0.03);
          margin-right: 20px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .curriculum-card-header-select {
        width: 50%;
        padding: 15px 0;

        .c-select {
          width: 100%;
        }
      }
    }
    &:last-child {
      margin-bottom: 60px;
    }

    .curriculum-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ant-empty-normal {
        margin: 20px 0;

        .ant-empty-description {
          margin: 0;
          font-family: $font-medium;
          color: $text-color;
          font-size: 12px;
        }
      }

      .teacher-section {
        border-bottom: 1px solid $stroke-color;
      }

      h3 {
        text-align: left;
        margin: 0;
        font-size: 14px;
        color: $text-color;
        font-family: $font-medium;
      }

      p {
        margin: 0 0 8px;
        font-size: 15px;
        font-family: $font-bold;
        color: $primary;
      }

      .curriculum-list {
        display: flex;
        flex-direction: row;

        span {
          width: 33.33%;
          font-size: 15px;
          padding: 3px;
          color: $text-color;
          font-family: $font-regular;

          &:nth-child(2) {
            text-align: center;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      .see-all {
        font-size: 14px;
        color: $secondary;
        font-family: $font-bold;
        cursor: pointer;
      }
    }

    .curriculum-card-footer {
      display: flex;
      justify-content: flex-end;
      margin: 15px 0 0 0;

      .link {
        font-size: 15px;
        color: #111C55;
        font-family: $font-bold;
        cursor: pointer;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.conditional-category {
  display: flex;
  font-weight: 900;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  color: #202020;
}
.categories-grades-model {
  width: 100%;
  background-color: "#FFFAFA";
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.10) 0.95px 0.95px 1.6px;
  
}
.categories-subject-model{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 5px 10px;
  border-radius:5px;
 
  font-weight: bolder;
}
.categories-model-subject-style{
  width: 100%;
  background-color: "#FFFAFA";
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.10) 0.95px 0.95px 1.6px;
  flex-direction: row;
  align-items: center;

}
.subjects{
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.10) 0.95px 0.95px 1.6px;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}

.styling{
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.curriculum-card-header-down{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.curriculum-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  padding: 1px;
  box-shadow: 0 0 10px rgb(227, 224, 224);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}
.curriculum-card-header-down-heading{
  text-align: right;
}

.curriculum-card-footer-down{
  background-color: #efefef;
  border-radius: 10px;
  padding: 5px 10px;
}
.spanstyling{
  background-color: #efefef;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 5px;
}

.curriculum-list-down{
  display: flex;
  justify-content: space-between;
  text-align: left;
  background-color: "#efefef" ;
  font-weight: 500;
  padding:  5px 10px;
  color: #202020;
}

.delete{
  margin-left: 30px ;
  color: "#ff0074" ;
  cursor: pointer;
}

.cancel{
color: #202020;
cursor: pointer;
margin-left: 30px ;
}

.top{
  display: none;
}

