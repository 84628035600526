.page-nav {
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
    font-size: 32px;
    font-family: $font-semiBold;
    color: $black;
    flex: 1;
  }
}

.pageheading{
  // background-color: #ffffff;
  // border-radius: 10px;
  // padding : 5px 10px;
  // box-shadow: rgba(0, 0, 0, 0.10) 0.95px 0.95px 1.6px;
  font-family: 'Poppins';
}
.pageheading > h1 {
  font-weight: 700;
  font-size: 50px;
  color: #21047c ;
  font-family:  "Poppins" ;

}