@media (min-width: 320px) and  (max-width: 991px) {
  .responsive {
    .auth-design-wrapper {
      position: relative;
      justify-content: center;
      align-items: center;

      .auth-design-wrapper-right {
        position: absolute;
        top: 35px;
        bottom: 35px;
        left: 15px;
        right: 15px;
        z-index: 1;
        flex-direction: column;
        justify-content: center;
        width: auto;
        .auth-design-wrapper-right-container {
          @include scroll-y;
          padding: 30px 20px;
          background-color: $white;
          border-radius: 15px;
          flex-direction: column;
          justify-content: flex-start;
          box-shadow: 0 0 12px -5px black;
          max-width: 500px;
          margin: 0 auto;
          .auth-design-wrapper-right-inner-container {
            min-width: 100%;
          }
          .inner-container {
            margin-top: 20px;
          }

          h1 {
            font-size: 26px;
            margin: 0 0 10px 0;
            line-height: 28px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .auth-design-wrapper-left {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .responsive {

    .page-nav {
      h1 {
        height: 25px;
        overflow: hidden;
      }
    }

    .flex-container {
      flex-direction: column;

      .flex-left {
        width: 100%;
        padding: 0;
      }

      .flex-right {
        width: 100%;
      }
    }

    .dashboard {
      .c-section-cards {
        flex-direction: column;

        .c-section-card {
          margin-bottom: 15px;
          width: calc(100% - 30px);
          min-height: 120px;
          padding: 15px;

          .c-section-card-title {
            font-size: 24px;
          }

          .c-section-card-sub-title {
            font-size: 14px;
          }

          &:after {
            width: 52px;
            height: 52px;
            background-size: 52px 52px;
          }
        }
      }
    }

    .c-button {
      font-size: 14px;
      padding: 5px 12px;
    }

    .hidden-mobile {
      display: none !important;
    }

    .show-mobile {
      display: flex !important;
    }

    .display-web {
      display: flex;
    }

    .c-tabs {
      .ant-tabs-bar {
        padding: 0 15px;
      }

      .content-container {
        padding: 20px 20px;
      }
    }

    .details-tab-container {
      .user-info {
        li {
          padding: 10px 0;
          flex-direction: column;

          span {
            &:first-child {
              font-size: 12px;
            }
          }

          &:last-child {
          }
        }
      }
    }

    .two-card-view {
      .second-card-view {
        .c-tabs {
          .ant-tabs-nav {
            padding: 0 15px;

            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 12px;
              }
            }
          }
        }

        .second-card-view-header {
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          .second-card-view-header-content {
            margin-top: 10px;
          }

          .close {
            position: absolute;
            right: 15px;
          }
          .with-out-background-responsive {
            position: absolute;
            right: 30px;
            margin: -3px 22px 0 0;
          }
        }
      }
    }

    .order-histories {
      .order-history-cards {
        flex-direction: column;
        margin: 0 0 25px;

        .order-history-card {
          width: 100%;
          padding: 10px;
          margin: 0 0 10px;

          span {
            font-size: 14px;

            &:first-child {
              margin-bottom: 3px;
            }
          }
        }
      }

      .order-list {
        .order-list-item {
          flex-direction: column;

          .order-list-item-container {
            width: 100%;

            img {
              width: 62px;
              height: 62px;
              margin-right: 10px;
            }

            .content {
              h6 {
                font-size: 13px;
              }

              p {
                font-size: 11px;
              }
            }

            &.order-list-order-content {
              padding: 10px 0;
              margin: 10px 0;
              border-left: none;
              border-right: none;
              border-top: 1px solid #e8ebf0;
              border-bottom: 1px solid #e8ebf0;
            }

            &.driver-profile {
              padding: 0;

              .driver-profile-detail {
                margin-bottom: 15px;
              }

              .image-container {
                margin-right: 10px;
              }

              .c-button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .approvals {
      .approval-list {
        .approval-list-item {
          flex-direction: column;

          .approval-list-item-content {
            margin: 0 0 5px 0;
            padding: 0;
            width: 100%;

            .text {
              font-size: 12px;
            }
          }

          .button-groups {
            width: 100%;
            justify-content: flex-end;

            .c-button {
              font-size: 12px;
              padding: 2px 12px;
              min-height: 32px;
            }
          }
        }
      }
    }

    .list {
      .list-item {
          flex-direction: column;
          align-items: flex-start;
        .text {
          margin: 0 0 5px;
          width: 100%;
        }
        .button-groups {
          width: 100%;
          margin: 5px 0 0;
          .c-button {
            flex: 1;
          }
        }
      }
    }

  }
  .user-detail-view {
    .user-image-with-name {
      img {
        width: 62px;
        height: 62px;
      }

      h4 {
        font-size: 16px;
      }
    }

    ul {
      li {
        flex-direction: column;

        span {
          font-size: 12px;

          &:last-child {
            font-size: 14px;
          }
        }

        &.license {
          flex-direction: row;
          flex-wrap: wrap;

          img {
            width: 200px;
            margin: 10px auto 10px;

            &:last-child {
              margin-right: auto;
            }
          }
        }
      }
    }
  }
  .chat-tab-container {
    .chat-container {
      .chat {
        .chat-content {
          span {
            font-size: 9px;
          }

          p {
            font-size: 13px;
          }
        }
      }
    }
  }
  .c-modal {
    padding: 0 !important;
    .close {
      left: auto !important;
      right: 15px;
      background-color: rgba(69, 90, 100, 1) !important;
    }

    .modal-dialog {
      //padding: 0;
      //margin: 0 15px !important;
      .modal-content {
        width: 100% !important;
        margin: 0;
        padding: 0;
        //height: 100% !important;
        //height: auto !important;
      }
    }

    .consentFormList {
      overflow-y: scroll;
      margin-bottom: 25px;
    }

    &.right-side {
      .modal-dialog {
        margin: 0 0 0 auto;
        height: 100%;
        min-width: 300px;
        transform: translate3d(0%, 0, 0) !important;
        right: -100%;
      }
    }
  }
  .appointment-list {
    .appointment-list-item {
      width: 100%;
      ul {
        li {
          .title {
            font-size: 12px;
          }
          .text {
            font-size: 14px;
          }
        }
      }
    }
  }
  .two-form-item {
    flex-direction: column;
    .two-form-item-left {
      margin: 0;
      width: 100%;
    }
    .two-form-item-right {
      margin: 0;
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .responsive {
    .c-tabs {
      .content-container {
        padding: 20px;
      }
    }
    .appointment-list {
      margin: 0 -7.5px;
      .appointment-list-item {
        margin: 7.5px;
        width: calc(50% - 15px);
        ul {
          li {
            .title {
              font-size: 12px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1450px) {
  .responsive {
    .dashboard {
      .section-filter {
        padding: 0;
      }

      .c-section-cards {
        flex-wrap: wrap;
        margin: 0 -7.5px 25px;

        .c-section-card {
          margin: 0 7.5px 15px;
          width: calc(50% - 15px);
          min-height: 120px;
          padding: 15px;

          .c-section-card-title {
            font-size: 24px;
          }

          .c-section-card-sub-title {
            font-size: 14px;
          }

          &:after {
            width: 52px;
            height: 52px;
            background-size: 52px 52px;
          }
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1400px) {
  .responsive {
    .appointment-list {
      margin: 0 -7.5px;
      .appointment-list-item {
        margin: 7.5px;
        width: calc(50% - 15px);
        ul {
          li {
            .title {
              font-size: 12px;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .responsive {
    .side-menu {
      .side-menu-header {
        .logo-container {
          .logo {
            margin: 0 10px auto;
          }
        }
      }
    }
    .body-with-side-menu {
      position: relative;

      .side-menu-container {
        position: fixed;
        left: -100%;
        top: 0;
        bottom: 0;
        right: 0;
        width: 320px;
        min-width: 320px;
        z-index: 11;
        transition: 0.3s;

        .side-menu {
          .side-menu-header {
            .side-menu-header-button {
              display: flex;
            }
          }
        }
      }

      .body-container {
        width: 100%;
        padding: 0 0;
      }

      &.left-drawer-open {
        .side-menu-container {
          left: 0;
        }
      }

      &.right-drawer-open {
        //.side-menu-container {
        //  left: 0;
        //}
        .two-card-view {

        }
      }

      &.open {
        .content-overlay {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .page-nav {
      align-items: center;
      padding: 20px 0;

      h1 {
        font-size: 18px;
        margin-right: 15px;
      }

      .menu-button {
        display: flex;
      }
    }

    .page-title {
      font-size: 18px;
      margin: 0 0 20px 0;
    }

    .two-card-view {
      margin: 0;
      position: relative;
      overflow: hidden;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      .first-card-view {
        width: 100%;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .first-card-view-header {
          padding: 15px;
          min-height: auto;
          align-items: center;

          h1 {
            font-size: 18px;
          }
        }

        .first-card-view-filter {
          padding: 15px;
        }

        .first-card-view-body {
          padding: 0 0 15px;

          .c-user-list {
            .c-user-list-item {
              padding: 15px !important;
            }
          }
        }
      }

      .second-card-view {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        left: auto;
        z-index: 1;
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        transition: 0.3s;

        .second-card-view-header {
          padding: 15px 15px;
        }
      }

      &.active {
        .second-card-view {
          right: 0;
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .responsive {
    .page-title {
      font-size: 22px;
    }

    .page-nav {
      h1 {
        font-size: 26px;
      }
    }

    .two-card-view {
      .first-card-view {
        width: 320px;
        .first-card-view-header {
          padding: 25px;

          h1 {
            font-size: 20px;
          }

          .search-bar {
            right: 62px;

            &.active {
              width: calc(100% - 102px) !important;
            }
          }
        }

        .first-card-view-body {
          .c-user-list {
            .c-user-list-item {
              padding: 15px;
            }
          }
        }
      }

      .second-card-view {
        width: calc(100% - 320px);
        .second-card-view-header {
          padding: 20px 15px;
          min-height: 72px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .app-root {
    &.top-view {
      flex-direction: row !important;
      .body-with-side-menu {
        flex-direction: column !important;
        width: 100%;
        .side-menu-container {
          width: 100%;
          .side-menu {
            flex-direction: row;
            padding: 0;
            .side-menu-header {
              margin: 0;
              padding: 10px 20px;
            }
            .menu {
              margin: 0;
              padding: 0 15px;
              display: flex;
              align-items: center;
              ul {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                li {
                  margin: 0 10px;
                }
              }
            }
            .user {
              align-items: center;
            }
          }
        }
        .body-container {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
