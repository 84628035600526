.c-card-messages {
  width: calc(50% - 20px);
  .c-card-messages-left {
    img {
      width: 42px;
      height: 42px;
      border-radius: 42px;
    }
  }
}
