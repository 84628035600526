.announcements {
  .two-card-view {
    .first-card-view{
      .first-card-view-header{
        padding: 33px 40px;
        min-height: 100px;
      }
    }
    .second-card-view {
      .second-card-view-header{
        &.without-tabs {
          padding: 30px 40px;
        }
      }
      .chat-tab-container {
        padding: 0;
        .chat {
          padding: 30px;
        }
      }
    }
  }
}
