.c-calendar {
  display: flex;
  flex-direction: column;
  .c-calendar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0 15px 0;
    .c-calendar-header-button {
      width: 12px;
      height: 12px;
      display: flex;
      background-color: red;
      &.button-prev {}
      &.button-next {}
    }
    .c-calendar-header-label {
      //flex: 1;
      font-size: 14px;
      color: $primary;
      font-family: $font-bold;
      margin: 0 15px;
    }
  }
  .c-calendar-body {
    .c-calendar-body-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #E8EBF0;
      padding: 10px 0;
      thead {
        tr {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 5px;
          th {
            font-size: 12px;
            color: $text-color;
            font-family: $font-medium;
            padding: 5px;
            text-align: center;
            flex: 0.1428;
          }
        }
        border-bottom: 1px solid $stroke-color;
      }
      tbody {
        display: flex;
        flex-direction: column;
        tr {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          td {
            flex: 0.1428;
            text-align: center;
            cursor: pointer;
            padding: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 12px;
              color: $primary;
              font-family: $font-medium;
              margin: 0;
              min-width: 30px;
              min-height: 30px;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid transparent;
            }
            &.today {
              span {
                border-color: $secondary;
                background-color: $secondary;
                color: $white;
              }
            }
            &:hover {
              span {
                border-color: $secondary;
              }
            }
          }
        }
      }
    }
  }
}
