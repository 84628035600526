.my-finances {
  .c-section {
    .c-section-header {
      .c-search-button {
        border: none;
        background-color: transparent;
        color: $secondary;
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        outline: none;
        font-size: 18px;
        margin: auto 0 auto 20px;
      }
    }

    .c-clear-all-button {
      display: flex;

      span {
        font-size: 13px;
        font-family: $font-semiBold;
        color: $text-color;
        cursor: pointer;
        margin: auto;
      }
    }
  }

  .button-groups {
    display: flex;
    flex-direction: row;

    button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  .page-content {
    .c-card {
      padding: 30px;
    }
  }
}

.finances {
  margin-bottom: 40px;

  .page-title {
    margin-bottom: 10px !important;
  }

  .finances-sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;

    .finances-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      // width: calc(25% - 30px);
      width: calc(33.33% - 30px);
      padding: 20px;
      margin: 15px;

      .finances-content {
        flex: 1;

        h4 {
          font-size: 26px;
          font-family: $font-bold;
          color: $primary;
          margin: 0 0 5px 0;
        }

        p {
          font-size: 14px;
          font-family: $font-medium;
          color: $text-color;
          margin: 0;
        }
      }
    }
  }
}

.refund-content {
  border-radius: 9px;
  border: 1px solid $stroke-color;
  padding: 5px 10px 5px 10px;
  box-shadow: 0 2px 9px rgba(1, 3, 17, 0.03);

  h4 {
    font-size: 16px;
    font-family: $font-bold;
    color: $text-color;
    margin: 0 0 5px 0;
  }

  p {
    font-size: 12px;
    font-family: $font-medium;
    color: $text-color;
    margin: 0;
  }
}



.check-hours-section {
  flex: 1;

  h4 {
    font-size: 26px;
    font-family: $font-bold;
    color: $primary;
    margin: 0 0 5px 0;
  }

  p {
    font-size: 14px;
    font-family: $font-medium;
    color: $text-color;
    margin: 0;
  }
}

.finances-content-justify{
  display: flex;
  justify-content: space-between;
}

.finances-content-justify-tag{
  font-weight: 700;
  font-family: 'Poppins';
}

.image-styling{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

