.auth-design-wrapper {
  .auth-design-wrapper-left {
    flex: 1;
    .auth-design-wrapper-left-container {
      justify-content: center;
      align-items: center;
      h1 {
        margin: 0 0 15px 0;
        text-align: center;
        font-family: $o-font-bold;
        font-weight: 900;
        font-size: 40px;
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        .dot{
          font-size: 40px;
          color: $secondary;
        }
      }
      p {
        margin: 0;
        text-align: center;
        padding: 0;
        font-family: $font-regularLegacy;
        font-size: 16px;
        color: $primary;
      }
      .inner-container {
        margin-top: 35px;
      }
    }
  }
  .auth-design-wrapper-right {
    width: 50%;
    img {
      width: 300px;
      height: 100px;
      // object-fit: cover;
    }
  }
}
.warning{
font-size: 10px !important;
  color: #e76853 !important;
}

.logo-style{
  width: 100px;
  height: 100px;
}

.login-style{
  font-weight: 700;
  font-size: 30px;
  font-family: 'Poppins';
  color: #111c55;

}
.logo-tag{
  width: 300px;
  height: 100px;
  text-align: center;

}
.logo-background{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../assets//images/welcome-background.jpeg') ;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(25, 25, 93, 0.2) 0px 0px 12px 0px, ;
}

.c-input-check{
  margin-bottom: 20px;
}