.c-user-list {
  list-style: none;
  .c-user-list-item {
    cursor: pointer;
    padding: 10px 30px;
    transition: 0.3s;
    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 15px;
    }
    .c-user-list-item-info {
      width: 90%;
      h6 {
          margin: 0 0 8px;
          font-size: 15px;
          font-family: $font-bold;
          color: $primary;
      }
      .ellipsis-text {
        text-overflow: ellipsis;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      box-shadow: -4px 6px 20px rgba(1, 3, 17, 0.05);
    }
    &:hover {
      @extend .active;
    }
  }
}
