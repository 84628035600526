.ant-collapse {
  background-color: transparent;
  border: none;
  .ant-collapse-item-active {
    .ant-collapse-header {
      .list-item {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        box-shadow: none;
      }
    }
  }
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      padding: 0;
      .ant-collapse-arrow {
        color: $text-color;
      }
      .ant-collapse-arrow-rotate {
        transform: rotate(-90deg);
        top: 25px;
      }
      .list-item {
        box-shadow: none;
      }
    }
    .ant-collapse-content {
      border: 0.5px solid #e8ebf0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
