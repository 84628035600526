$primary: #111c55;
$secondary: #111c55;
$white: #ffffff;
$black: #000000;
$text-color: #b0b1b6;
$wild-blue: #a1a8cb;
$alice-blue: #f6f9fe;
$stroke-color: #e8ebf0;
$orange: #ff7d36;
$green: #91c503;
$purple: #a681e3;
$gray: #979797;
$linkColor: #ff0074;
$starColor: #f8cf4f;

//font families
$font-bold : 'HKGrotesk-Bold';
$font-italic : 'HKGrotesk-Italic';
$font-light : 'HKGrotesk-Light';
$font-medium : 'HKGrotesk-Medium';
$font-regular : 'Poppins';
$font-regularLegacy : 'Poppins';
$font-semiBold : 'Poppins';

$o-font-bold: "Amiri-Bold";
$o-font-boldItalic: "Poppins";
$o-font-italic: "Amiri-Italic";
$o-font-regular: "Poppins";



