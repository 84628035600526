.c-type-list {
  list-style: none;
  .c-type-list-item {
    //margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 0;
    transition: 0.3s;
    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 15px;
      object-fit: cover;
    }
    .c-type-list-item-info {
      h6 {
        margin: 0 0 8px;
        font-size: 15px;
        font-family: $font-bold;
        color: $primary;
        span {
          font-family: $font-medium;
          color: $text-color;
        }
      }
      .child-img {
        height: 30px;
        width: 30px;
        margin-left: 10px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      background: rgba(236, 238, 255, 0.5);
      //box-shadow: -4px 6px 20px rgba(1, 3, 17, 0.05);
    }
    &:hover {
      @extend .active;
    }
  }
}
