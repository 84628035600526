.side-menu {
  background: #111c55;
  padding: 20px 15px;
  .logo {
    width: 117px;
    height: 40px;
    margin-bottom: 15px;
    &:hover {
      transform: scale(1.05, 1.05);
    }
    img {
      width: 150px;
      height: 100%;
    }
  }
  .menu {
    height: calc(100% - 110px);
    margin: 0 -15px 15px -15px;
    padding: 0 17px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 5px;
      a {
        padding: 12px;
        border-radius: 9px;
        background-color: transparent;
        display: flex;
        text-decoration: none;
        align-items: center;
        transition: 0.3s;
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        span {
          flex: 1;
          font-size: 16px;
          color: $wild-blue;
          font-family: $font-medium;
          transition: 0.3s;
        }
        i {
          color: $wild-blue;
          font-size: 16px;
          margin-right: 10px;
          transition: 0.3s;
          &:before {
            transition: 0.3s;
          }
          ;
        }
        &.active {
          background-color: rgba(208, 249, 255, 0.1203);
          i {
            color: $white;
            &:before {
              color: $white;
            }
          }
          span {
            color: $white;
          }
        }
        &:hover {
          @extend .active;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .user {
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      border-radius: 35px;
      object-fit: cover;
    }
    .user-info {
      flex: 1;
      margin: 0 15px;
      span {
        color: $wild-blue;
        font-size: 14px;
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:first-child {
          font-family: $font-bold;
          font-size: 12px;
        }
        &:last-child {
          font-family: $font-medium;
        }
      }
    }
  }
}

