.topSubject-container {
    position: relative;
    width: 100%;
    height: 100%;

    h3 {
        text-align: left;
        margin: 0;
        font-size: 14px;
        color: $text-color;
        font-family: $font-medium;
      }
  }