table, th, td {
    border:1px solid black;
  }

  th, td{
    padding-left: 1vw;
  }
.promotion-color-title{
    border: 1px solid #000;
    padding: 0px 0 0px 1vw;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.promotion-file-input{
    border: 1px solid #000;
    padding: 0px 0 0px 1vw;
    overflow-x: scroll;
    margin-top: 20px;
    border-radius: 5px;
    height: 50px;
    padding-top: 2.5%;
}
.promotion-color-div{
    border: 1px solid #000;
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    height: 50px;
    padding: 0px 1vw 0px 1vw;
    align-items: center;
}
.promotion-color-select{
    padding: 0px 0 0px 1vw;
    border-radius: 5px;
    height: 50px;
    margin-top: 20px;
}
.promotion-submit{
    height: 50px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #111c55;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.promotion-delete-submit{
    width: 40%;
    background-color: #111c55;
    color: #fff;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    font-weight: 600;
}
.fa-trash-can:hover{
    cursor: pointer;
}
.fa-pen:hover{
    cursor: pointer;
}