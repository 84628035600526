.details-tab-container {
  .user-info {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0; 
    li {
      padding: 15px 0;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $stroke-color;
      span {
        font-size: 15px;
        color: $text-color;
        font-family: $font-medium;
        flex: 0.5;
        &:first-child {
        }
        &:last-child {
          color: $primary;
        }
      }
    }
  }
}
.questions-tab-container {
  width: 100%;
  .c-card-tab {
    .ant-tabs-card-bar {
      padding: 0 !important;
      width: 100%;
      border: none;
      .ant-tabs-nav-container {
        width: 100%;
      }
      .ant-tabs-nav {
        display: flex;
        div {
          width: 100%;
          background: #f6f9fe;
          padding: 5px;
          border-radius: 7px;
          .ant-tabs-tab {
            width: 50%;
            margin: 0;
            border: none;
            border-radius: 6px;
            padding: 10px;
            text-align: center;
            font-family: $font-semiBold;
            font-size: 13px;
            &.ant-tabs-tab-active {
              background: $white;
              box-shadow: 0 0 4px rgba(17, 28, 85, 0.14);
            }
            &:first-child {
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
  .question-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 7.5px -7.5px;
    .question-card {
      background-color: $alice-blue;
      border-radius: 9px;
      padding: 15px;
      margin: 7.5px;
      width: calc(50% - 15px);
      .question-card-header {
        .replied {
          font-size: 11px;
          font-family: $font-medium;
          color: $text-color;
        }
        .user-question {
          display: flex;
          flex-direction: row;
          margin: 10px 0;
          .user-image {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            margin-right: 10px;
          }
          .user-question-info {
            flex: 1;
            p {
              margin: 0 0 8px 0;
              color: $text-color;
              font-size: 14px;
              font-family: $font-medium;
              display: flex;
              flex-direction: row;
              align-items: center;
              span {
                color: rgba(120, 125, 152, 0.6);
                font-size: 10px;
                margin-left: 10px;
              }
            }
            h3 {
              margin: 0;
              color: $primary;
              font-size: 23px;
              font-family: $o-font-regular;
            }
            .c-tags {
              margin-top: 8px;
            }
          }
        }
        h4 {
          margin: 10px 0;
          font-size: 23px;
          font-family: $o-font-regular;
          color: $primary;
        }
      }
      .question-card-body {
        .message-list {
          .message-list-item {
            display: flex;
            img {
              width: 30px;
              height: 30px;
              border-radius: 30px;
              margin-right: 10px;
            }
            p {
              flex: 1;
              margin: 0;
              font-size: 14px;
              color: $text-color;
              font-family: $font-medium;
              a {
                color: $secondary;
                font-size: 14px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .question-card-footer {
        padding: 10px 0 0 40px;
        .input-with-button {
          position: relative;
          input {
            box-shadow: none;
            padding: 5px 45px 5px 15px;
          }
          button {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
            color: $secondary;
            position: absolute;
            right: 10px;
            top: 10px;
            svg {
              font-size: 24px;
            }
          }
        }
      }
      &.main {
        .question-card-body {
          padding: 0 0 0 52px;
        }
        .question-card-footer {
          padding: 10px 0 0 52px;
        }
      }
    }
  }
}
.invoicing-status-tab-container {
  .invoicing-hours-section {
    margin: 15px 0 35px;
    .invoicing-hours-inner-section {
      background: $white;
      border: 0.5px solid $stroke-color;
      box-shadow: 0 2px 30px rgba(120, 125, 152, 0.08);
      border-radius: 10px;
      width: 50%;
      padding: 30px;
      h2 {
        margin: 0 0 10px 0;
        font-size: 30px;
        line-height: 24px;
        font-family: $font-bold;
        color: $primary;
      }
      span {
        font-size: 16px;
        font-family: $font-medium;
        color: $text-color;
      }
      &.left {
        margin-right: 15px;
      }
      &.right {
        margin-left: 15px;
      }
    }
  }
  .list {
    margin-top: 5px;
  }
}
.parents-comments-tab-container {
  height: 100%;
  .pm-comments-container {
    height: calc(100% - 45px);
    .pm-comments {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      .pm-comment {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          margin-right: 15px;
        }
        span {
          font-family: $font-medium;
          font-size: 12px;
          color: $text-color;
          display: flex;
          margin-bottom: 5px;
        }
        p {
          margin: 0;
          font-family: $font-medium;
          font-size: 15px;
          color: $primary;
        }
      }
    }
  }
  .pm-comments-input-container {
    input {
      padding: 0 45px 0 65px;
    }
    .pm-comments-input-button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      color: $secondary;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      svg {
        font-size: 24px;
      }
      &.right {
        right: auto;
        left: 15px;
        border-right: 2px solid $stroke-color;
        padding-right: 10px;
      }
    }
  }
}
.progress-component-tab-container {
  .c-section {
    .c-section-multiple-titles {
      .c-section-title {
        &:first-child {
          margin-right: 30px;
        }
        &:nth-child(2) {
        }
        &:last-child {
          margin-right: 40px;
        }
      }
    }
  }
  .card-views {
    .card-view {
      width: calc(50% - 7.5px);
      &:first-child {
        margin-right: 7.5px;
      }
      &:last-child {
        margin-left: 7.5px;
      }
    }
  }
}
.homework-inner {
  display: flex;
  flex-direction: column;
  .file-list {
    margin: 0 0 15px 0;
    .file-list-item {
      background-color: rgba(239, 245, 255, 0.63);
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
      .file-list-item-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        .heading {
          font-size: 15px;
          color: $primary;
          font-family: $font-bold;
          margin: 0 0 5px;
        }
        .text {
          color: $text-color;
          font-family: $font-medium;
          font-size: 12px;
        }
      }
      .file-list-item-actions {
        display: flex;
        flex-direction: row;
        span {
          cursor: pointer;
          line-height: 0;
          color: #cbd1e0;
          font-size: 22px;
          transition: 0.3s;
          margin-right: 10px;
          &:hover {
            color: $secondary;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  h1 {
    color: $primary;
    font-family: $o-font-bold;
    font-size: 30px;
    margin-bottom: 20px;
  }
}

//rating styling
.rating {
  height: 100%;
  .c-rating-section {
    flex-direction: column;
    text-align: center;
    height: calc(100% - 45px);
    display: flex;
    justify-content: center;
    align-items: center;
    .c-rating-header {
      .c-rating-main-title {
        margin: 0 0 30px 0;
        color: $primary;
        font-size: 22px;
        font-family: $font-bold;
        b {
          color: $secondary;
        }
      }
    }
    .c-rating-main {
      display: flex;
      justify-content: center;
      p {
        margin: 0 10px 0 0;
        color: $text-color;
        font-size: 12px;
        font-family: $font-medium;
        margin-bottom: 2px;
      }
      .c-rating-stars {
        color: $starColor;
        font-size: 30px;
      }
    }
  }
  .c-rating-section-footer {
    .input-with-button {
      position: relative;
      input {
        box-shadow: none;
        padding: 5px 45px 5px 15px;
      }
      button {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        color: $secondary;
        position: absolute;
        right: 10px;
        top: 10px;
        svg {
          font-size: 24px;
        }
      }
    }
  }
}

// payment detail
.finance {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 30px 0;
  padding: 30px;
  .finance-content {
    flex: 0.8;
    h3 {
      margin: 0;
      font-size: 22px;
      font-family: $font-semiBold;
      color: $primary;
    }
    p {
      margin: 20px 0;
      font-size: 16px;
      font-family: $font-medium;
      color: $text-color;
    }
    button {
      //min-width: 145px;
      padding: 5px 20px;
    }
    margin-right: 50px;
  }
  .finance-image {
    width: 240px;
    height: 69px;
    object-fit: contain;
    margin: 0 auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.get-paid-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  margin: 15px;
  .get-paid-content {
    flex: 1;
    h4 {
      font-size: 26px;
      font-family: $font-bold;
      color: $primary;
      margin: 0 0 5px 0;
    }
    p {
      font-size: 14px;
      font-family: $font-medium;
      color: $text-color;
      margin: 0;
    }
  }
}
.promos {
  .c-card-promo {
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    .c-card-promo-header {
      .title {
        font-size: 16px;
        font-family: $font-semiBold;
        color: $primary;
        margin: 0 0 5px 0;
      }
    }
    .c-card-promo-timing {
      display: flex;
      span {
        width: 50%;
        font-size: 12px;
        font-family: $font-medium;
        color: $text-color;
      }
    }
    .promo-code {
      font-size: 16px;
      font-family: $font-semiBold;
      color: $primary;
      display: flex;
      align-items: center;
      .secondary-text {
        margin-left: 5px;
        text-transform: none;
      }
    }
    .c-tags{
      margin: 10px 0 10px;
    }
    .c-card-promo-description {
      margin:0 0 15px !important;
    }
    .c-card-promo-footer {
      margin-top: 10px;
      button {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.subject-container {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  //align-items: flex-start;
  .subject-card {
    width: calc(33.33% - 20px);
    // cursor: pointer;
    padding-bottom: 0;
  }
}

.personal-statement-heading{
  font-weight: 'bold';
  color: #202020;
}

.hr-style{
  width: 100%;
}

.personal-statement-tag{
  color: #787d98;
  font-weight: bold;
}

.tutors-heading{
  font-family: 'Poppins';
  color:  #21047c;
}

.stop{

    height: 100%;
    overflow-y: hidden;

}