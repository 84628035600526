.dashboard{
  width: 100%;
  .flex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    .flex-left {
      width: 100%;
      // padding-right: 50px;
    }
    .flex-right {
      width: 0px;
    }
  }
}

.graph-with-value-card {
  display: flex;
  flex-direction: row;
  .graph {
    width: 50%;
  }
  .values-card {
    width: 50%;
    .value-card {
      margin-bottom: 20px !important;
      h4 {
        font-size: 26px;
        font-family: $font-regularLegacy;
        color: $primary;
        margin: 0 0 5px 0;
      }
      p {
        font-size: 14px;
        font-family: $font-medium;
        color: $text-color;
        margin: 0;
      }
      &:last-child {
        margin-bottom: 10px !important;
      }
    }
  }
}
.graph {
  width: 100%;
  display: flex;
  align-items: center;
  .c-card {
    .c-card-title {
      font-size: 20px;
      color: #21047c;
      font-weight: 600;
      // font-family: $font-regularLegacy;
      font-family: "Poppins";
      margin: 0 0 25px 0;
      align-items: center;
    }
    flex: 1;
    &.circle-progress-container {
      .circle-progress-inner-container {
        width: 180px;
        height: 180px;
        margin: auto;
        position: relative;
        .icon-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 42px;
            height: 42px;
            color: #a9afcf;
          }
        }
      }
      .circle-progress-values {
        display: flex;
        align-items: center;
        margin: 24px 0 0;
        .circle-progress-value {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: var(--color);
            margin: 7px 10px;
          }
          .circle-progress-value-info {
            h6 {
              margin: 0;
              text-align: left;
              font-size: 22px;
              color: $primary;
              font-family: $font-bold;
              span {
                margin: 0 0 0 5px;
                font-size: 12px;
                color: $text-color;
                font-family: $font-medium;
              }
            }
            p {
              text-align: left;
              margin: 0;
              font-size: 14px;
              color: $text-color;
              font-family: $font-medium;
            }
          }
        }
      }
    }
    &.dashboard-progress-container {
      .dashboard-progress-inner-container {
        position: relative;
        width: 180px;
        height: 160px;
        margin: auto;
        .dashboard-progress-text{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin: 0;
            font-size: 26px;
            color: $primary;
            font-family: $font-bold;
          }
        }
      }
    }
    &.square-progress-container{
      .square-progress-inner-container {
        .square-progress {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .ant-progress-inner {
            border-radius: 0;
            .ant-progress-bg {
              border-radius: 5px !important;
            }
          }
          span {
            margin: 0 25px;
            text-align: left;
            font-size: 14px;
            color: $primary;
            font-family: $font-bold;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .square-progress-values {
        display: flex;
        flex-direction: row;
        margin: 25px 50px 0;
        .square-progress-value {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 25px;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: var(--color);
            margin: 0 10px 0 0;
          }
          p {
            text-align: left;
            margin: 0;
            font-size: 14px;
            color: $text-color;
            font-family: $font-medium;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.card-value {
  display: flex;
  justify-content: center;
  span {
    margin: 0 0 10px 0;
    font-size: 26px;
    color: $primary;
    font-family: $font-bold;
  }
}

.teacher-info {
  padding: 30px 50px;
  .normal-text {
    font-size: 14px;
    color: $text-color;
    font-family: $font-medium;
    span{
      color: $primary;
    }
  }
  .info-list {
    display: flex;
    flex-direction: row;
    .info-list-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .info-list-item-title {
        font-size: 18px;
        font-family: $font-bold;
        color: $primary;
      }
      .info-list-item-status {
        display: flex;
        &:before{
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background: $wild-blue;
          display: flex;
          margin: 6px 5px 6px -10px;
        }
      }
      &:first-child {
        .info-list-item-status {
          &:before{
            background: #F74362;
          }
        }
      }
      &:nth-child(2) {
        flex: 1;
        .info-list-item-status {
          &:before{
            background: #93C30B;
          }
        }
      }
      &:last-child {
        .info-list-item-status {
          &:before{
            background: #FF9D28;
          }
        }
      }
    }
  }
}



.c-card-title-span {
  margin-right: 10px ;
  background-color:#Add8e6 ;
  border-radius: 5px;
  padding: 5px;
}
.c-card-heading{
  display: flex;
  justify-content: space-between;
}

.shadow-overlay{
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.shadow-image{
  position: relative;
  top: 25px;
  left: 25px;
}

.ant-input{
  border: 1px solid #111c55;
  box-shadow: none;

}
.ant-input:hover{
  border: 1px solid #111c55;
  box-shadow: none;

}
.ant-input:active{
  border: 1px solid #111c55;
  box-shadow: none;
}

.dashboard-span{
  font-size: 16px;
  color: #21047c ;

}

.weight{
  font-weight: 500;
}

.family{
  font-family: 'Poppins';
  font-weight: 700;
}

.c-card:hover{
  box-shadow: 6px 6px 15px rgba(218, 213, 213, 0.363);
  transition: all 0.5s;
  cursor: pointer;

}

.align {
  display: flex;
  justify-content: space-between;
}
